'use strict';

var _isBoolean = require('lodash/isBoolean');

var _isBoolean2 = _interopRequireDefault(_isBoolean);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

module.exports = {
  name: 'boolean',
  checker: _isBoolean2.default,
  printer: JSON.stringify
}; /* eslint algolia/no-module-exports: 0 */