'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var ExceptionThrower = function () {
  function ExceptionThrower(path) {
    var parent = arguments.length <= 1 || arguments[1] === undefined ? '' : arguments[1];

    _classCallCheck(this, ExceptionThrower);

    this.path = parent + '[' + path + ']';
  }

  _createClass(ExceptionThrower, [{
    key: 'nest',
    value: function nest(name) {
      return new ExceptionThrower(name, this.path);
    }
  }, {
    key: 'throw',
    value: function _throw(message) {
      throw new Error(this.path + ' ' + message);
    }
  }]);

  return ExceptionThrower;
}();

exports.default = ExceptionThrower;