'use strict';

var _isPlainObject = require('lodash/isPlainObject');

var _isPlainObject2 = _interopRequireDefault(_isPlainObject);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

module.exports = {
  name: 'Object',
  checker: _isPlainObject2.default,
  printer: JSON.stringify
}; /* eslint algolia/no-module-exports: 0 */