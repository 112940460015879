'use strict';

var _isNumber = require('lodash/isNumber');

var _isNumber2 = _interopRequireDefault(_isNumber);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

module.exports = {
  name: 'number',
  checker: _isNumber2.default,
  printer: JSON.stringify
}; /* eslint algolia/no-module-exports: 0 */