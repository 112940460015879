'use strict';

var _undefined = require('./src/types/undefined.js');

var _undefined2 = _interopRequireDefault(_undefined);

var _null = require('./src/types/null.js');

var _null2 = _interopRequireDefault(_null);

var _boolean = require('./src/types/boolean.js');

var _boolean2 = _interopRequireDefault(_boolean);

var _number = require('./src/types/number.js');

var _number2 = _interopRequireDefault(_number);

var _string = require('./src/types/string.js');

var _string2 = _interopRequireDefault(_string);

var _function = require('./src/types/function.js');

var _function2 = _interopRequireDefault(_function);

var _Array = require('./src/types/Array.js');

var _Array2 = _interopRequireDefault(_Array);

var _Object = require('./src/types/Object.js');

var _Object2 = _interopRequireDefault(_Object);

var _OptionsManager = require('./src/OptionsManager.js');

var _OptionsManager2 = _interopRequireDefault(_OptionsManager);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

module.exports = function () {
  return new _OptionsManager2.default().registerTypes([_undefined2.default, _null2.default, _boolean2.default, _number2.default, _string2.default, _function2.default, _Array2.default, _Object2.default]);
}; /* eslint algolia/no-module-exports: 0 */