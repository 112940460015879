'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _forEach = require('lodash/forEach');

var _forEach2 = _interopRequireDefault(_forEach);

var _isNull = require('lodash/isNull');

var _isNull2 = _interopRequireDefault(_isNull);

var _isUndefined = require('lodash/isUndefined');

var _isUndefined2 = _interopRequireDefault(_isUndefined);

var _Structure = require('./Structure.js');

var _Structure2 = _interopRequireDefault(_Structure);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var FunctionChecker = function () {
  function FunctionChecker(name, shared) {
    _classCallCheck(this, FunctionChecker);

    this.name = name;
    this.options = [];
    this.errors = [];
    this.shared = shared;
  }

  _createClass(FunctionChecker, [{
    key: 'arg',
    value: function arg(name, structure) {
      if ((0, _isUndefined2.default)(structure)) {
        structure = name;
        name = null;
      }
      if (!(structure instanceof _Structure2.default)) {
        var path = (0, _isNull2.default)(name) ? 'arguments[' + this.options.length + ']' : name;
        structure = new _Structure2.default(structure, this.shared, path);
      }
      this.options.push({ name: name, structure: structure });
      return this;
    }
  }, {
    key: '_legend',
    value: function _legend() {
      var res = ['<...> Type', '*     Required'];
      if (this.errors.length > 0) res.push('X     Error');
      return res;
    }
  }, {
    key: 'usage',
    value: function usage() {
      var _this = this;

      var usages = [];
      (0, _forEach2.default)(this.options, function (_ref) {
        var structure = _ref.structure;
        var name = _ref.name;

        usages.push(structure.usage(name, _this.errors));
      });
      var res = [];
      res.push('Usage:\n  ' + this.name + '(\n' + usages.join(',\n') + '\n  )');
      if (this.errors.length > 0) {
        var errorsStr = this.errors.map(function (_ref2) {
          var actualPath = _ref2.actualPath;
          var message = _ref2.message;
          return '  - `' + actualPath + '` ' + message;
        }).join('\n');
        res.push('Errors:\n' + errorsStr);
      }
      res.push('Legend:\n  ' + this._legend().join('\n  '));
      return '\n' + res.join('\n----------------\n');
    }
  }, {
    key: 'check',
    value: function check() {
      var _this2 = this;

      this.errors = [];
      (0, _forEach2.default)(this.options, function (_ref3) {
        var structure = _ref3.structure;
        var value = _ref3.value;

        structure.check(value, _this2.errors);
      });
      if (this.errors.length > 0) throw new Error(this.usage());
    }
  }, {
    key: 'values',
    value: function values() {
      var entries = arguments.length <= 0 || arguments[0] === undefined ? [] : arguments[0];

      var res = [];
      (0, _forEach2.default)(this.options, function (option, i) {
        option.value = entries[i];
        res.push(option.structure.buildValue(option.value));
      });
      this.check();
      return res;
    }
  }]);

  return FunctionChecker;
}();

exports.default = FunctionChecker;