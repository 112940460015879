'use strict';

var _isFunction = require('lodash/isFunction');

var _isFunction2 = _interopRequireDefault(_isFunction);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

module.exports = {
  name: 'function',
  checker: _isFunction2.default,
  printer: function printer(f) {
    var name = f.name || 'anonymous';
    var args = f.length ? f.length + ' argument' + (f.length > 1 ? 's' : '') : '';
    return name + '(' + args + ')';
  }
}; /* eslint algolia/no-module-exports: 0 */