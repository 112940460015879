'use strict';

var _isUndefined = require('lodash/isUndefined');

var _isUndefined2 = _interopRequireDefault(_isUndefined);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

module.exports = {
  name: 'undefined',
  checker: _isUndefined2.default,
  printer: function printer() {
    return 'undefined';
  }
}; /* eslint algolia/no-module-exports: 0 */