'use strict';

var _isArray = require('lodash/isArray');

var _isArray2 = _interopRequireDefault(_isArray);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

module.exports = {
  name: 'Array',
  checker: _isArray2.default,
  printer: JSON.stringify
}; /* eslint algolia/no-module-exports: 0 */