'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _isArray = require('lodash/isArray');

var _isArray2 = _interopRequireDefault(_isArray);

var _Store2 = require('./Store.js');

var _Store3 = _interopRequireDefault(_Store2);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var TypeCheckerStore = function (_Store) {
  _inherits(TypeCheckerStore, _Store);

  function TypeCheckerStore(thrower) {
    _classCallCheck(this, TypeCheckerStore);

    return _possibleConstructorReturn(this, Object.getPrototypeOf(TypeCheckerStore).call(this, thrower, 'TypeCheckerStore'));
  }

  _createClass(TypeCheckerStore, [{
    key: 'check',
    value: function check(types, value) {
      var _this2 = this;

      var thrower = this.thrower.nest('.check');
      if (!(0, _isArray2.default)(types)) {
        thrower.throw('Types `' + types + '` should be an array');
      }
      if (types.length === 0) {
        thrower.throw('Types `[]` must have at least one element');
      }
      var res = false;
      // Let the loop run until the end, we want it to break
      // if it fails for any type
      types.forEach(function (type) {
        if (_this2.get(type)(value)) res = true;
      });
      return res;
    }
  }]);

  return TypeCheckerStore;
}(_Store3.default);

exports.default = TypeCheckerStore;