'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol ? "symbol" : typeof obj; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _clone = require('lodash/clone');

var _clone2 = _interopRequireDefault(_clone);

var _find = require('lodash/find');

var _find2 = _interopRequireDefault(_find);

var _forEach = require('lodash/forEach');

var _forEach2 = _interopRequireDefault(_forEach);

var _isArray = require('lodash/isArray');

var _isArray2 = _interopRequireDefault(_isArray);

var _isFunction = require('lodash/isFunction');

var _isFunction2 = _interopRequireDefault(_isFunction);

var _isNumber = require('lodash/isNumber');

var _isNumber2 = _interopRequireDefault(_isNumber);

var _isNull = require('lodash/isNull');

var _isNull2 = _interopRequireDefault(_isNull);

var _isPlainObject = require('lodash/isPlainObject');

var _isPlainObject2 = _interopRequireDefault(_isPlainObject);

var _isString = require('lodash/isString');

var _isString2 = _interopRequireDefault(_isString);

var _isUndefined = require('lodash/isUndefined');

var _isUndefined2 = _interopRequireDefault(_isUndefined);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var Structure = function () {
  function Structure(structure, shared, path) {
    var _this = this;

    var parentPath = arguments.length <= 3 || arguments[3] === undefined ? null : arguments[3];
    var isElement = arguments.length <= 4 || arguments[4] === undefined ? false : arguments[4];

    _classCallCheck(this, Structure);

    var thrower = shared.thrower;
    var typeCheckers = shared.typeCheckers;
    var typePrinters = shared.typePrinters;


    this.path = path;
    this.fullPath = '' + ((0, _isNull2.default)(parentPath) ? '' : parentPath) + path;

    // Structure
    if ((0, _isUndefined2.default)(structure)) {
      thrower.throw('`' + this.fullPath + '`\'s `structure` must be defined');
    }

    // Types
    if (!(0, _isString2.default)(structure.type)) {
      thrower.throw('`' + this.fullPath + '` must have a `type`');
    }
    this.types = structure.type.split('|');
    this.typeCheck = typeCheckers.check.bind(typeCheckers, this.types);
    this.typesStr = this.types.join('|');

    // Required and default value
    this.required = structure.required === true;
    this.hasValue = structure.hasOwnProperty('value') || structure.hasOwnProperty('computeValue');
    this.value = (structure.computeValue || function () {
      return structure.value;
    })();
    this.computeValue = structure.computeValue;
    if (this.hasValue) {
      this.valueStr = typePrinters.get(this.types[0])(this.value);
    }
    if (this.hasValue && this.required) {
      thrower.throw('`' + this.fullPath + '` can\'t be `required` and have a `value`');
    }

    // Validators
    this.validators = structure.validators || [];

    // Own element
    this.isElement = isElement;
    if (this.isElement) {
      if (this.hasValue) {
        thrower.throw('`' + this.fullPath + '` is an `element`, it can\'t have a `value`');
      }
      if (this.required) {
        thrower.throw('`' + this.fullPath + '` is an `element`, it can\'t be `required`');
      }
    }

    // Element and Children
    this.hasElement = !(0, _isUndefined2.default)(structure.element);
    this.hasChildren = !(0, _isUndefined2.default)(structure.children);
    if (this.hasElement && this.hasChildren) {
      thrower.throw('`' + this.fullPath + '`\'s structure can\'t have both `element` and `children`');
    }

    // Children
    this.children = null;
    if (this.hasChildren) {
      this.children = (0, _isArray2.default)(structure.children) ? [] : {};
      (0, _forEach2.default)(structure.children, function (childStructure, childName) {
        var childPath = (0, _isArray2.default)(structure.children) ? '[' + childName + ']' : '.' + childName;
        _this.children[childName] = new Structure(childStructure, shared, childPath, _this.fullPath);
      });
    }

    // Element
    this.element = null;
    if (this.hasElement) {
      this.element = new Structure(structure.element, shared, '[]', this.fullPath, true);
    }
  }

  _createClass(Structure, [{
    key: 'buildValue',
    value: function buildValue(value) {
      var _this2 = this;

      if ((0, _isUndefined2.default)(value) && this.hasValue) {
        if ((0, _isFunction2.default)(this.computeValue)) {
          value = this.computeValue();
        } else {
          var tmp = (0, _clone2.default)(this.value);
          if ((0, _isPlainObject2.default)(tmp) && !(0, _isPlainObject2.default)(this.value)) {
            // Not clonable
            value = this.value;
          } else {
            value = tmp;
          }
        }
      }
      if (!(0, _isNull2.default)(this.children)) {
        (0, _forEach2.default)(this.children, function (childStructure, key) {
          var res = childStructure.buildValue(value[key]);
          if (!(0, _isUndefined2.default)(res)) value[key] = res;
        });
      }
      if (!(0, _isNull2.default)(this.element)) {
        (0, _forEach2.default)(value, function (childValue, index) {
          value[index] = _this2.element.buildValue(childValue);
        });
      }
      return value;
    }
  }, {
    key: '_addError',
    value: function _addError(errors, message, parentPath, index) {
      if ((0, _isNull2.default)(parentPath)) parentPath = '';
      errors.push({
        actualPath: '' + parentPath + (this.isElement ? '[' + index + ']' : this.path),
        message: message,
        structurePath: this.isElement ? parentPath : this.fullPath
      });
    }
  }, {
    key: 'check',
    value: function check(value, errors) {
      var _this3 = this;

      var parentPath = arguments.length <= 2 || arguments[2] === undefined ? null : arguments[2];
      var index = arguments.length <= 3 || arguments[3] === undefined ? null : arguments[3];

      var type = typeof value === 'undefined' ? 'undefined' : _typeof(value);

      // Element definition
      if (this.isElement) {
        var noUndefined = this.types.indexOf('undefined') === -1 && this.types.indexOf('any') === -1;
        if ((0, _isUndefined2.default)(value) && noUndefined) {
          this._addError(errors, 'should be defined', parentPath, index);
          return;
        }
      }

      // Required
      if (this.required && (0, _isUndefined2.default)(value)) {
        this._addError(errors, 'is required', parentPath, index);
        return;
      }

      // Skip if not required and absent
      if ((0, _isUndefined2.default)(value) && !this.isElement && !this.required) return;

      // Type error
      if (!this.typeCheck(value)) {
        var message = 'should be <' + this.typesStr + '>, received ' + type;
        this._addError(errors, message, parentPath, index);
        return;
      }

      // Validators
      for (var i = 0; i < this.validators.length; ++i) {
        var vRes = this.validators[i](value);
        if (vRes !== true) {
          this._addError(errors, vRes, parentPath, index);
          return;
        }
      }

      // Recursive calls
      if (this.hasChildren) {
        (0, _forEach2.default)(this.children, function (childStructure, key) {
          childStructure.check(value[key], errors, _this3.fullPath);
        });
      }

      if (this.hasElement) {
        (0, _forEach2.default)(value, function (childValue, childIndex) {
          _this3.element.check(childValue, errors, _this3.fullPath, childIndex);
        });
      }
    }
  }, {
    key: 'usage',
    value: function usage() {
      var name = arguments.length <= 0 || arguments[0] === undefined ? null : arguments[0];

      var _this4 = this;

      var errors = arguments.length <= 1 || arguments[1] === undefined ? [] : arguments[1];
      var indent = arguments.length <= 2 || arguments[2] === undefined ? 4 : arguments[2];

      var indentation = new Array(indent + 1).join(' ');

      var requiredChar = this.required ? '*' : ' ';
      var errorChar = (0, _find2.default)(errors, { structurePath: this.fullPath }) ? 'X' : ' ';
      var prefix = requiredChar + errorChar + indentation.substring(0, indentation.length - 2);

      var hasName = !(0, _isUndefined2.default)(name) && !(0, _isNull2.default)(name) && !(0, _isNumber2.default)(name);
      if (!hasName) name = '';

      var res = '' + prefix + name + '<' + this.typesStr + '>';

      if ((!(0, _isNull2.default)(this.children) || !(0, _isNull2.default)(this.element)) && hasName) res += ': ';

      if (!(0, _isNull2.default)(this.children)) {
        (function () {
          var childrenArray = (0, _isArray2.default)(_this4.children);
          res += childrenArray ? '[' : '{';
          res += '\n';
          var childrenUsages = [];
          (0, _forEach2.default)(_this4.children, function (childStructure, childName) {
            childrenUsages.push(childStructure.usage(childName, errors, indent + 2));
          });
          res += childrenUsages.join(',\n');
          res += '\n' + indentation;
          res += childrenArray ? ']' : '}';
        })();
      }

      if (!(0, _isNull2.default)(this.element)) {
        res += '[' + this.element.usage(null, errors, indent).substr(indent) + ']';
      }

      if (this.hasValue) res += ' = ' + this.valueStr;
      return res;
    }
  }]);

  return Structure;
}();

exports.default = Structure;