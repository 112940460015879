'use strict';

var _isString = require('lodash/isString');

var _isString2 = _interopRequireDefault(_isString);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

module.exports = {
  name: 'string',
  checker: _isString2.default,
  printer: JSON.stringify
}; /* eslint algolia/no-module-exports: 0 */