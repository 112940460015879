'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _Template = require('../Template.js');

var _Template2 = _interopRequireDefault(_Template);

var _utils = require('../../lib/utils.js');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var ClearAll = function (_React$Component) {
  _inherits(ClearAll, _React$Component);

  function ClearAll() {
    _classCallCheck(this, ClearAll);

    return _possibleConstructorReturn(this, (ClearAll.__proto__ || Object.getPrototypeOf(ClearAll)).apply(this, arguments));
  }

  _createClass(ClearAll, [{
    key: 'componentWillMount',
    value: function componentWillMount() {
      this.handleClick = this.handleClick.bind(this);
    }
  }, {
    key: 'shouldComponentUpdate',
    value: function shouldComponentUpdate(nextProps) {
      return this.props.url !== nextProps.url || this.props.hasRefinements !== nextProps.hasRefinements;
    }
  }, {
    key: 'handleClick',
    value: function handleClick(e) {
      if ((0, _utils.isSpecialClick)(e)) {
        // do not alter the default browser behavior
        // if one special key is down
        return;
      }
      e.preventDefault();
      this.props.clearAll();
    }
  }, {
    key: 'render',
    value: function render() {
      var data = {
        hasRefinements: this.props.hasRefinements
      };

      return _react2.default.createElement(
        'a',
        {
          className: this.props.cssClasses.link,
          href: this.props.url,
          onClick: this.handleClick
        },
        _react2.default.createElement(_Template2.default, _extends({
          data: data,
          templateKey: 'link'
        }, this.props.templateProps))
      );
    }
  }]);

  return ClearAll;
}(_react2.default.Component);

exports.default = ClearAll;