'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _isFunction = require('lodash/isFunction');

var _isFunction2 = _interopRequireDefault(_isFunction);

var _isNull = require('lodash/isNull');

var _isNull2 = _interopRequireDefault(_isNull);

var _isString = require('lodash/isString');

var _isString2 = _interopRequireDefault(_isString);

var _isUndefined = require('lodash/isUndefined');

var _isUndefined2 = _interopRequireDefault(_isUndefined);

var _ExceptionThrower = require('./ExceptionThrower.js');

var _ExceptionThrower2 = _interopRequireDefault(_ExceptionThrower);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var Store = function () {
  function Store() {
    var thrower = arguments.length <= 0 || arguments[0] === undefined ? null : arguments[0];
    var name = arguments.length <= 1 || arguments[1] === undefined ? 'Store' : arguments[1];

    _classCallCheck(this, Store);

    this.thrower = (0, _isNull2.default)(thrower) ? new _ExceptionThrower2.default(name) : thrower.nest(name);
    this.store = {};
  }

  _createClass(Store, [{
    key: 'get',
    value: function get(name) {
      var thrower = this.thrower.nest('.get');

      if (!(0, _isString2.default)(name)) {
        thrower.throw('Name `' + name + '` should be a string');
      }

      var res = this.store[name];

      if ((0, _isUndefined2.default)(res)) {
        thrower.throw('Name `' + name + '` has no associated function');
      }

      return res;
    }
  }, {
    key: 'set',
    value: function set(name, func) {
      var method = arguments.length <= 2 || arguments[2] === undefined ? 'set' : arguments[2];

      var thrower = this.thrower.nest('.' + method);
      if (!(0, _isString2.default)(name)) {
        thrower.throw('Name `' + name + '` should be a string');
      }
      if (!(0, _isFunction2.default)(func)) {
        thrower.throw('Function `' + func + '` should be a function');
      }
      this.store[name] = func;
    }
  }, {
    key: 'add',
    value: function add(name, func) {
      this.set(name, func, 'add');
    }
  }]);

  return Store;
}();

exports.default = Store;