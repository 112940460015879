'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _isUndefined = require('lodash/isUndefined');

var _isUndefined2 = _interopRequireDefault(_isUndefined);

var _ExceptionThrower = require('./ExceptionThrower.js');

var _ExceptionThrower2 = _interopRequireDefault(_ExceptionThrower);

var _FunctionChecker = require('./FunctionChecker.js');

var _FunctionChecker2 = _interopRequireDefault(_FunctionChecker);

var _Structure = require('./Structure.js');

var _Structure2 = _interopRequireDefault(_Structure);

var _TypeCheckerStore = require('./TypeCheckerStore.js');

var _TypeCheckerStore2 = _interopRequireDefault(_TypeCheckerStore);

var _TypePrinterStore = require('./TypePrinterStore.js');

var _TypePrinterStore2 = _interopRequireDefault(_TypePrinterStore);

var _ValidatorStore = require('./ValidatorStore.js');

var _ValidatorStore2 = _interopRequireDefault(_ValidatorStore);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function defaultPrint(elt) {
  return elt.toString();
}

var OptionsManager = function () {
  function OptionsManager() {
    _classCallCheck(this, OptionsManager);

    this.thrower = new _ExceptionThrower2.default('OptionsManager');
    this.typeCheckers = new _TypeCheckerStore2.default(this.thrower);
    this.typePrinters = new _TypePrinterStore2.default(this.thrower);
    this.validators = new _ValidatorStore2.default(this.thrower);
    this._shared = {
      thrower: this.thrower,
      typeCheckers: this.typeCheckers,
      typePrinters: this.typePrinters
    };
  }

  _createClass(OptionsManager, [{
    key: 'registerType',
    value: function registerType(_ref) {
      var name = _ref.name;
      var checker = _ref.checker;
      var _ref$printer = _ref.printer;
      var printer = _ref$printer === undefined ? defaultPrint : _ref$printer;

      this.typeCheckers.add(name, checker);
      this.typePrinters.add(name, printer);
      return this;
    }
  }, {
    key: 'registerTypes',
    value: function registerTypes(types) {
      types.forEach(this.registerType, this);
      return this;
    }
  }, {
    key: 'registerValidator',
    value: function registerValidator(_ref2) {
      var name = _ref2.name;
      var validator = _ref2.validator;

      this.validators.add(name, validator);
      return this;
    }
  }, {
    key: 'registerValidators',
    value: function registerValidators(validators) {
      validators.forEach(this.registerValidator, this);
      return this;
    }
  }, {
    key: 'validator',
    value: function validator(name) {
      for (var _len = arguments.length, args = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        args[_key - 1] = arguments[_key];
      }

      return this.validators.get(name).apply(undefined, args);
    }

    // Accepts (name, obj) or just (obj)

  }, {
    key: 'structure',
    value: function structure(name, obj) {
      if ((0, _isUndefined2.default)(obj)) {
        obj = name;
        name = '';
      }
      return new _Structure2.default(obj, this._shared, name);
    }
  }, {
    key: 'check',
    value: function check(name) {
      var shared = _extends({}, this._shared, {
        thrower: this.thrower.nest('.check')
      });
      return new _FunctionChecker2.default(name, shared);
    }
  }]);

  return OptionsManager;
}();

exports.default = OptionsManager;