'use strict';

var _isNull = require('lodash/isNull');

var _isNull2 = _interopRequireDefault(_isNull);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

module.exports = {
  name: 'null',
  checker: _isNull2.default,
  printer: function printer() {
    return 'null';
  }
}; /* eslint algolia/no-module-exports: 0 */